import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
import "core-js/modules/es6.function.name.js";
import _toConsumableArray from "/mnt/c/Users/scaas/Desktop/tinymac/renew-plat/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
import "core-js/modules/es6.function.name.js";
import * as authService from '@/libs/auth';
import * as loginService from '@/service/login';
import Helper from "@/libs/helper";
var user = {
  state: {
    token: authService.getToken(),
    userInfo: {
      name: '',
      avatar: '',
      email: '',
      oauth: []
    },
    role: ''
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USERINFO: function SET_USERINFO(state, userInfo) {
      state.userInfo = userInfo;
      state.role = userInfo.role;
    }
  },
  actions: {
    //用户名登录
    LoginByUserName: function LoginByUserName(_ref, userinfo) {
      var commit = _ref.commit,
          dispatch = _ref.dispatch;
      var username = userinfo.username.trim();
      var vm = this._vm; // console.log("thisvmvm");
      // console.log(this);

      if (typeof vm.helper.get === 'undefined') vm.helper = new Helper(vm, null);
      return new Promise(function (resolve, reject) {
        vm.helper.postForm("auth/login", {
          name: username,
          password: userinfo.password
        }, function (response) {
          var data = response.data.data;
          commit('SET_TOKEN', data.accessToken); //delete userInfo.accessToken;

          commit('SET_USERINFO', data);
          authService.setToken(data.accessToken);
          console.log('登录用户', data); //-通过paker获取user_id

          vm.helper.getPaker("/providing/userId", data).then(function (res) {
            localStorage.setItem("info", res.result); //-console.log(66666,vm.helper.base64Decode(res.result));
          });
          resolve();
          localStorage.setItem("SessionId", data.SessionId);
          dispatch('GetUserInfo');
        }), function () {
          reject(error);
        }; // loginService.loginByUsername(username, userinfo.password).then(response => {
        //     const data = response.data.data
        //     commit('SET_TOKEN', data.accessToken)
        //     const userInfo = {
        //       name: data.name,
        //       avatar: data.avatarUrl
        //     }
        //   commit('SET_USERINFO', userInfo)
        //     authService.setToken(data.accessToken)
        //     resolve()
        // }).catch(error => {
        //     reject(error)
        // })
      });
    },
    // 登出
    LogOut: function LogOut(_ref2, state) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        loginService.logout().then(function (response) {
          commit('SET_TOKEN', '');
          commit('SET_USERINFO', {
            name: '',
            avatar: '',
            email: '',
            oauth: '',
            role: ''
          });
          authService.removeToken();
          localStorage.removeItem('info');
          resolve();
        }).catch(function (error) {
          commit('SET_TOKEN', '');
          commit('SET_USERINFO', {
            name: '',
            avatar: '',
            email: '',
            oauth: '',
            role: ''
          });
          authService.removeToken();
          localStorage.removeItem('info');
          reject(error);
        });
      });
    },
    GetUserInfo: function GetUserInfo(_ref3, state) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        loginService.getUserInfo().then(function (response) {
          var data = response.data.data;
          var userInfo = {
            name: data.name,
            avatar: data.avatarUrl,
            email: data.email,
            oauth: data.oauth,
            role: data.role
          };
          commit('SET_USERINFO', userInfo);
          var userRole = data.userRole;
          var userPermission = data.userPermission;
          var permission = [].concat(_toConsumableArray(userRole), _toConsumableArray(userPermission));
          var isAdmin = data.isAdmin;
          localStorage.setItem("name", data.name);
          localStorage.setItem("role", data.role);
          localStorage.setItem("emaill", data.email);
          localStorage.setItem("permission", JSON.stringify(permission));
          localStorage.setItem("isAdmin", isAdmin);
          resolve(data);
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  }
};
export default user;