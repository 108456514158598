import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.filter.js";
export default (function (_ref) {
  var Vue = _ref.Vue;
  Vue.component('ghost', {
    functional: true,
    // props: ['show'],
    render: function render(h, ctx) {
      if (typeof ctx.children == "undefined") return ctx;
      var children = ctx.children.filter(function (vnode) {
        return vnode.tag;
      }); // remove unnecessary text nodes
      // if (children.length !== 1) {
      // }

      return children; // if (ctx.props.show) {
      //   return children[0]
      // } else {
      //   return children[0].children
      // }
    }
  });
});