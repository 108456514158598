import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.filter.js";
export default {
  data: function data() {
    return {
      isSelected: false,
      isExpanded: false
    };
  },
  props: {
    hasExpand: null,
    hasSelect: null,
    selected: null,
    expanded: null
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler: function handler(newVal) {
        var _this = this;

        this.isSelected = (newVal || []).filter(function (x) {
          return x.key === _this.slotData.key;
        }).length > 0;
      }
    },
    expanded: {
      immediate: true,
      deep: true,
      handler: function handler(newVal) {
        var _this2 = this;

        this.isExpanded = (newVal || []).filter(function (x) {
          return x.key === _this2.slotData.key;
        }).length > 0;
      }
    }
  },
  methods: {
    rowsActionChange: function rowsActionChange(isChosen, event, values) {
      var key = this.slotData.key;
      var selected = values;
      if (!selected) selected = [];
      selected = selected.filter(function (x) {
        return x.key !== key;
      });

      if (isChosen) {
        selected.push(this.slotData);
      }

      this.$emit(event, selected, key, this.slotData);
    }
  },
  computed: {}
};