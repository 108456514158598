import request from '@/libs/request';
import stringify from "qs/lib/stringify";
export function loginByUsername(name, password) {
  var data = {
    name: name,
    username: name,
    password: password
  };
  return request({
    url: '/auth/login',
    method: 'post',
    data: stringify(data)
  });
}
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post',
    loading: "hourglass"
  });
}
export function getUserInfo() {
  return request({
    url: '/auth/user/info',
    method: 'get',
    loading: "gears"
  });
}