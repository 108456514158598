import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';import QSpinner from 'quasar/src/components/spinner/QSpinner.js';;
var loading = {};
var lastRequest = new Date('2018');

loading.show = function (config) {
  if (config && config.loading) {
    var now = new Date();
    var ms = now - lastRequest;
    lastRequest = now;

    if (ms > 2000) {
      //相隔两秒的请求才重新显示loading
      if (config.loading == "gears") {
        Loading.show({
          spinner: QSpinnerGears,
          message: '',
          messageColor: 'white',
          spinnerSize: 100,
          spinnerColor: 'white',
          customClass: ''
        });
      } else if (config.loading == "hourglass") {
        Loading.show({
          // spinner: QSpinnerHourglass,
          message: '',
          messageColor: 'white',
          spinnerSize: 100,
          spinnerColor: 'white',
          customClass: ''
        });
      }
    }
  }
};

loading.hide = function (config) {
  if (config && config.loading) {
    setTimeout(function () {
      Loading.hide();
    }, 1000);
  }
};

export default loading;