//
//
//
//
//
//
//
//
import { Fragment } from 'vue-frag';
import Vue from "vue";
import Rows from "./Rows";
import ScItem from "../ScItem";
export default Vue.extend({
  mixins: [Rows],
  components: {
    ScItem: ScItem,
    Fragment: Fragment
  },
  name: "CrudsProxy",
  mounted: function mounted() {},
  created: function created() {},
  props: {
    name: String,
    viewMode: {
      type: String,
      default: "card" //cell

    },
    get: null,
    slotData: null
  }
});