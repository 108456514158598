import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.regexp.replace.js"; //
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScPop from "./ScPop";
export default {
  name: "ScHelper",
  components: {
    ScPop: ScPop
  },
  data: function data() {
    return {
      hasBracket: false,
      helperText: "",
      labelText: ""
    };
  },
  watch: {
    label: {
      handler: function handler(v) {
        if (!this.helper.is(v)) {
          this.hasBracket = false;
          return;
        }

        v = this.$t(v);
        this.hasBracket = v.indexOf("(") > -1 && v.indexOf(")") > -1;

        if (this.hasBracket) {
          this.helperText = v.replace(/.*?\((.*?)\)/, "$1");
          this.labelText = v.replace(/(.*)\(.*?\).*?$/, "$1");
        }
      },
      immediate: true
    }
  },
  methods: {},
  created: function created() {},
  props: {
    value: Object,
    label: String,
    icon: {
      type: String,
      default: "help_outline"
    },
    prefix: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    }
  }
};