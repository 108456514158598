import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.function.name.js";
import Helper from "@/libs/helper";
export default (function (_ref) {
  var app = _ref.app,
      router = _ref.router,
      store = _ref.store,
      Vue = _ref.Vue;
  if (app == null) console.log(Vue);
  var userActiveTimeout = 10 * 1000;
  Vue.prototype.helper = Helper;
  Vue.prototype.h = Helper;
  Helper.Vue = Vue;
  Vue.mixin({
    data: function data() {
      return {};
    },
    computed: {
      isLoggedIn: function isLoggedIn() {
        return this.$store.state.app.isLoggedIn;
      },
      isOpenLogin: function isOpenLogin() {
        return this.$store.state.app.isOpenLogin;
      }
    },
    // watch:{
    //   "$store.state.app.isLoggedIn":{
    //     immediate:true,
    //     handler:function(newVal){
    //       console.log("newVal");
    //       console.log(newVal);
    //       this.isLoggedIn=newVal;
    //     }
    //   }
    // },
    methods: {
      layoutListenUserLastActiveHandler: function layoutListenUserLastActiveHandler() {
        clearTimeout(window.userLastActiveHandler);
        window.userLastActiveHandler = setTimeout(function () {// this.$root.$emit("auth.timeout",this)
        }, userActiveTimeout);
      }
    },
    mounted: function mounted() {
      window.userLastActive = new Date().getTime();

      if (this.$options.name == "SoarDragonLayout" && this.$el.classList.contains("q-layout")) {
        window.addEventListener('keyup', this.layoutListenUserLastActiveHandler);
        window.addEventListener('scroll', this.layoutListenUserLastActiveHandler);
        window.addEventListener('mousemove', this.layoutListenUserLastActiveHandler);
        window.addEventListener('mousedown', this.layoutListenUserLastActiveHandler);
      }
    },
    setup: function setup(prop, context) {
      var name = context.root.$options.name;
      var denyList = "App,transition,RouterLink".split(","); // if(Helper.is(name) && name.substr(0,1)!=="Q" && denyList.indexOf(name)==-1){
      // console.log(66666666666);

      context.helper = new Helper(context.root, app); // context.h=context.root.helper;
      // }

      if (name == "App") {
        context.app = app;
      }
    },
    beforeCreate: function beforeCreate() {
      var name = this.$options.name; //this.$options.data.isLoggedIn=false;

      var denyList = "App,transition,RouterLink".split(",");

      if (Helper.is(name) && name.substr(0, 1) !== "Q" && denyList.indexOf(name) == -1) {
        // console.log("this.$options.name");
        // console.log(this.$options.name);
        //console.log("name="+name);
        this.helper = new Helper(this, app);
        this.h = this.helper;
      }

      if (name == "App") {
        this.app = app;
      }
    }
  });
});