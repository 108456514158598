//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "ScPop",
  components: {},
  data: function data() {
    return {};
  },
  watch: {},
  methods: {},
  created: function created() {},
  props: {
    value: Object,
    transitionShow: String,
    transitionHide: String,
    offset: Array,
    anchor: String,
    self: String
  }
};