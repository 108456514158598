export default (function (_ref) {
  var app = _ref.app,
      router = _ref.router,
      store = _ref.store,
      Vue = _ref.Vue;
  Vue.directive('permission', {
    bind: function bind(el, binding, vnode) {
      var needPermissions = binding.value;
      var permissions = JSON.parse(localStorage.getItem('permission'));
      var isAdmin = localStorage.getItem('isAdmin');
      var hasPermission = permissions.some(function (s) {
        return needPermissions.indexOf(s) > -1;
      });

      if (!hasPermission && isAdmin == 0) {
        el.style.display = "none";
      }
    }
  });
});