import Vue from 'vue';
import Vuex from 'vuex';
import app from "./modules/app";
import user from "./modules/user";
import pay from "./modules/pay";
import pagination from "./modules/pagination";
import getFee from "./modules/fee";
import discount from "./modules/discount";
import getters from "./getters";
Vue.use(Vuex);
var store = new Vuex.Store({
  mutations: {//
  },
  actions: {},
  modules: {
    app: app,
    user: user,
    pay: pay,
    pagination: pagination,
    getFee: getFee,
    discount: discount
  },
  getters: getters
});
export default store;