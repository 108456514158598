import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.function.name.js";
import axios from 'axios';
import Router from '@/router/index';
import { getToken, removeToken } from '@/libs/auth';
import loading from '@/libs/loading';
import permission from '@/libs/permission';
import Notify from 'quasar/src/plugins/Notify.js';;
import store from '@/store/index.js';
import Helper from '@/libs/helper';
var helper = new Helper(); // create an axios instance

var api = process.env.ENV_PRD == true && (document.location.hostname == "scaas.lliff.com" || document.location.hostname == "amazon1.lliff.com" || document.location.hostname == "amazonsummit.scaas.lliff.com") ? "https://api.scaas.lliff.com" : process.env.API; // api的base_url

if (process.env.dev) {
  api = 'http://paker.uat.scaas.com';
}

;
var service = axios.create({
  baseURL: api,
  timeout: 20000,
  // request timeout
  headers: {
    'Cache-Control': 'no-cache'
  },
  withCredentials: true
}); // request interceptor

service.interceptors.request.use(function (config) {
  // Do something before request is sent
  // console.log(config.method.toUpperCase()+": "+config.url,typeof config.data=="undefined"?"":config.data);
  // let data = await this.helper.get("auth/verify_realname/mytop")
  if (!permission.check(config)) {
    Notify.create({
      message: translate("没有权限")
    });
    throw "403";
  }

  loading.show(config);
  var token = getToken();

  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token; // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改

    if (config.type === 'paker') {
      config.headers['ucode'] = helper.base64Encode(localStorage.getItem('name'));
      config.headers['info'] = localStorage.getItem('info');
    }
  } else {
    config.headers['ucode'] = JSON.stringify({
      cookie: helper.getCookie('ucode1'),
      application: localStorage.getItem('ucode2')
    });
  } //todo Loto 开了减少options，但无法登录
  //config.headers['Content-Type']='application/json';


  return config;
}, function (error) {
  // Do something with request error
  // console.warn(arguments) // for debug
  Promise.reject(error);
}); // respone interceptor

service.interceptors.response.use(function (response) {
  loading.hide(response.config);
  var res = response.data;
  var url = response.request.responseURL.replace(api, '');
  if (url.indexOf("/consumer/javascript/run") == 0) return response; //-console.log(url,res);
  // console.log(response);

  if (response.status === 200) {
    return response;
  }

  if (res.statusCode !== 200) {
    // console.log("statusCode error");
    // console.log(res);
    var message = "error";

    if (Helper.is(res.errors) && Helper.is(res.errors[0])) {
      message = res.errors[0].name;

      if (message == 'Error') {
        message = res.errors[0].message;
      }
    } else {
      message = res.msg;
    }

    return Promise.reject(message);
  } else {
    return response;
  }
}, function (error) {
  loading.hide(error.config);

  if (error.response && error.response.status === 401) {
    store.state.app.isOpenLogin = true;
    removeToken();

    if (error.config.url.indexOf("logout") === -1) {
      Notify.create({
        message: translate('登录超时')
      });
      store.state.app.isLoggedIn = false;
    }

    setTimeout(function () {// Router.push({
      //   name: "login"
      // });
    }, 1000);
  } else if (error.response && error.response.status === 500) {
    if (helper.jp.get(error.response, 'data.errors', false)) {
      if (error.response.data.errors[0].message === 'InternalAuthenticationServiceException') {
        Notify.create({
          message: translate('认证失败，请核实账号和密码,或联系客服！')
        });
      }

      if (error.response.data.errors[0].message === '验证码错误或已失效') {
        Notify.create({
          message: error.response.data.errors[0].message
        });
      }
    }
  } else if (error.message.indexOf("timeout") > -1) {
    Notify.create({
      message: translate('网络超时') + '!',
      position: 'bottom-right'
    });
  } else if (error == "403") {} else {
    Notify.create({
      message: translate('网络连接错误') + '!',
      position: 'bottom-right'
    });
  }

  return Promise.reject(error);
});

function translate(text) {
  try {
    return Helper.appCache.i18n.t(text);
  } catch (e) {
    throw e;
  }

  return text;
}

export default service;