var permission = {};

permission.check = function (config) {
  if (config.permission && config.permission.length > 0) {
    var needPermissions = config.permission;
    var permissions = JSON.parse(localStorage.getItem('permission'));
    var isAdmin = localStorage.getItem('isAdmin');
    var hasPermission = permissions.some(function (s) {
      return needPermissions.indexOf(s) > -1;
    });

    if (!hasPermission && isAdmin == 0) {
      return false;
    }
  }

  return true;
};

export default permission;