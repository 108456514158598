export var defaultAccessMenu = [{
  path: "",
  icon: "settings",
  title: "user",
  name: "user",
  leftMenu: true,
  children: [{
    path: "/cms",
    icon: "settings",
    title: "CMS",
    name: "CMS",
    leftMenu: true,
    children: [{
      path: "article",
      icon: "settings",
      title: "Articles",
      name: "article",
      leftMenu: true
    }]
  }]
}, {
  path: "",
  icon: "settings",
  title: "System",
  name: "system",
  leftMenu: true,
  children: [{
    path: "/system",
    icon: "settings",
    title: "System settings",
    name: "system settings",
    leftMenu: true,
    children: [{
      path: "menu",
      icon: "settings",
      title: "Menu management",
      name: "menu",
      leftMenu: true
    }]
  }, {
    path: "/permission",
    icon: "settings",
    title: "Authority management",
    name: "permission",
    leftMenu: true,
    children: [{
      path: "function",
      icon: "settings",
      title: "Function management",
      name: "function",
      leftMenu: true
    }, {
      path: "role",
      icon: "settings",
      title: "Role management",
      name: "role",
      leftMenu: true
    }, {
      path: "rolepermission",
      icon: "settings",
      title: "Role rights management",
      name: "rolepermission",
      leftMenu: true
    }, {
      path: "roleuser",
      icon: "settings",
      title: "Role user management",
      name: "roleuser",
      leftMenu: true
    }, {
      path: "userrole",
      icon: "settings",
      title: "Role user management",
      name: "userrole",
      leftMenu: true
    }]
  }, {
    path: "/organization",
    icon: "settings",
    title: "Organization",
    name: "organization",
    leftMenu: true,
    children: [{
      path: "department",
      icon: "settings",
      title: "Department",
      name: "department",
      leftMenu: true
    }, {
      path: "position",
      icon: "settings",
      title: "Position management",
      name: "position",
      leftMenu: true
    }]
  }, {
    path: "/user",
    icon: "settings",
    title: "User Management",
    name: "user",
    leftMenu: true,
    children: [{
      path: "index",
      icon: "settings",
      title: "User Management",
      name: "user_index",
      leftMenu: true
    }]
  }]
}, {
  path: "/",
  name: "otherRouter",
  leftMenu: false,
  children: [{
    path: "home",
    title: "Home",
    name: "home_index"
  }, {
    path: "userinfo",
    title: "Profile / User info",
    name: "userinfo"
  }]
}];