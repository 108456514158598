var getters = {
  cachePage: function cachePage(state) {
    return state.app.cachePage;
  },
  pageOpenedList: function pageOpenedList(state) {
    return state.app.pageOpenedList;
  },
  currentPath: function currentPath(state) {
    return state.app.currentPath;
  },
  accessMenu: function accessMenu(state) {
    return state.app.accessMenu;
  }
};
export default getters;